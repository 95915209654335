import { graphql, useStaticQuery } from 'gatsby';
import { map as _map, sortBy as _sortBy } from 'lodash';

import localeFilter, { localeFilterList } from '@lce/intl-util/src/localeFilter';
import { ILineObject } from '@lce/intl-types/src/ILineObject';
import removeLine from '@lce/intl-util/src/removeLine';

interface IDatoUseLocation {
  mapDisplay: IDatoMapDisplay;
  googleLocations: IDatoNodeGoogleLocation;
  locationDefault: IDatoNodeLocationDefault;
  locationsList: IDatoNodeLocationList;
  legalContent: IDatoCmsLegalText;
}

interface IDatoNodeGoogleLocation {
  nodes: IDatoGoogleListItem[];
}

interface IDatoNodeLocationList {
  nodes: IDatoLocationListItem[];
}

interface IDatoNodeLocationDefault {
  nodes: IDatoLocationDefault[];
}

interface IDatoCmsLegalText { 
  nodes: IDatoLegalText[];
}

interface IDatoMapDisplay {
  centerLatitude: number;
  centerLongitude: number;
  zoom: number;
  markerImage: {
    url: string;
  };
}

interface IDatoGoogleListItem {
  locale: string;
  address: ILineObject[];
  latitude: number;
  longitude: number;
  name: string;
  telephoneNumber: string;
}

interface IDatoLocationListItem {
  locale: string;
  position: number;
  address: ILineObject[];
  hours: ILineObject[];
  latitude: number;
  longitude: number;
  name: string;
  telephoneNumber: string;
}

interface IDatoLocationDefault {
  locale: string;
  pageTitle: string;
  hoursLabel: string;
  telephoneLabel: string;
  defaultHours: ILineObject[];
  sortedAlphabetically: boolean;
}

interface IDatoLegalText { 
  locale: string;
  legalText: string;
}

// eslint-disable-next-line max-lines-per-function
const useLocation = (locale: string) => {
  const data: IDatoUseLocation = useStaticQuery(graphql`
    query UseMapDisplayQuery {
      mapDisplay: datoCmsLocationMap {
        centerLatitude
        centerLongitude
        zoom
        markerImage {
          url
        }
      }
      googleLocations: allDatoCmsLocationList {
        nodes {
          locale
          latitude
          longitude
          name
          address {
            line
          }
          telephoneNumber
        }
      }
      locationDefault: allDatoCmsLocationListDefault {
        nodes {
          locale
          pageTitle
          hoursLabel
          telephoneLabel
          defaultHours {
            line
          }
          sortedAlphabetically
        }
      }
      locationsList: allDatoCmsLocationList {
        nodes {
          locale
          position
          address {
            line
          }
          hours {
            line
          }
          latitude
          longitude
          name
          telephoneNumber
        }
      }
      legalContent: allDatoCmsContactUsPageLegalText {
        nodes {
          locale
          legalText
        }
      }
    }
  `);

  const filteredLocationDefault = localeFilter<IDatoLocationDefault>(
    data.locationDefault && data.locationDefault.nodes,
    locale,
  );

  const filteredLocationsList = localeFilterList<IDatoLocationListItem>(data.locationsList.nodes, locale);
  const cleanedLocationList = _map(filteredLocationsList, location => ({
    ...location,
    address: removeLine(location.address),
    hours: removeLine(location.hours),
  }));

  const filteredCoordinates = localeFilterList<IDatoGoogleListItem>(data.googleLocations.nodes, locale);
  const cleanedCoordinates = _map(filteredCoordinates, coordinates => ({
    ...coordinates,
    position: { lat: coordinates?.latitude, lng: coordinates?.longitude },
    telephoneLabel: filteredLocationDefault?.telephoneLabel,
    address: removeLine(coordinates.address),
  }));

  const filteredLegalTextByLocale = localeFilter<IDatoLegalText>(data?.legalContent?.nodes, locale);

  const sortedLocationList = _sortBy(cleanedLocationList, (location) => filteredLocationDefault.sortedAlphabetically ? location.name : location.position);

  return {
    mapDisplay: {
      ...data.mapDisplay,
      center: {
        lat: data.mapDisplay.centerLatitude,
        lng: data.mapDisplay.centerLongitude,
      },
      locations: cleanedCoordinates,
      markerImage: data.mapDisplay.markerImage.url,
    },
    locationDefault: {
      ...filteredLocationDefault,
      defaultHours: removeLine(filteredLocationDefault.defaultHours),
      isSorted: filteredLocationDefault.sortedAlphabetically,
    },
    locationsList: {
      ...sortedLocationList,
    },
    legalContent: {
      ...filteredLegalTextByLocale,
    },
  };
};

export default useLocation;
