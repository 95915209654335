import * as React from 'react';
import { Marker, MarkerProps } from 'react-google-maps';

import locatorPin from './caesarpin-60x66.png';

export interface IMarker extends MarkerProps {
  children: React.ReactNode;
}

const LceMarker: React.FC<IMarker> = (props) => {
  const { children } = props;

  const defaultImage: google.maps.Icon = {
    url: locatorPin,
    scaledSize: typeof window !== 'undefined' && window.google && new (window as any).google.maps.Size(60, 66),
  };  

  const defaultProps = {
    icon: defaultImage,
    ...props,
  };

  return (
    <Marker
      { ...defaultProps }
    >
      { children }
    </Marker>
  );
};

export default LceMarker;
