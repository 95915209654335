import * as React from 'react';

import { IMapId } from '../types';

import { initialState } from './initialState';

import { MapIdActions, FranchiseeActionTypes } from './Action';
import { MapIdContext } from './MapIdContext';

const selectedMapIdReducer = (state: IMapId, action: FranchiseeActionTypes): IMapId => {
  switch (action.type) {
    case MapIdActions.SET_ID:
      return { ...state, selectedMapId: action.payload };
    case MapIdActions.UNSET_ID:
      return { ...state, selectedMapId: undefined };
    default:
      throw new Error('Unsupported action type');
  }
};

export const MapIdProvider: React.FC = (props) => {

  const [ state, dispatch ] = React.useReducer(selectedMapIdReducer, initialState);

  const value = React.useMemo(() => ({ state, dispatch }), [ state ]);
  return <MapIdContext.Provider value={ value } { ...props } />;
};

export default MapIdProvider;
