/* eslint-disable @typescript-eslint/no-type-alias */

export enum MapIdActions {
  SET_ID = 'SET_ID',
  UNSET_ID = 'UNSET_ID',
  FAILURE = 'FAILURE'
}

interface ISetMapIdAction {
  type: MapIdActions.SET_ID;
  payload: string;
}

interface IUnSetMapIdAction {
  type: MapIdActions.UNSET_ID;
}

interface IFailureMapIdAction {
  type: MapIdActions.FAILURE;
}

export type FranchiseeActionTypes =
  | ISetMapIdAction
  | IUnSetMapIdAction
  | IFailureMapIdAction;
