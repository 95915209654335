import * as React from 'react';
import { map as _map } from 'lodash';
import Box from '@lce/slice_v2/Layout/Box';
import Heading from '@lce/slice_v2/Elements/Heading';
import { Marker, InfoWindow } from '@lce/intl-map/src';
import { useSelectedMapId } from '@lce/intl-util/src/api/SelectedMapId/hooks/useSelectedMapId';
import { useUpdateMapId } from '@lce/intl-util/src/api/SelectedMapId/hooks/useUpdateMapId';

import { ILocationProps } from '../../index';
import ComponentListRenderer from '../../../../../Modules/ComponentListRenderer';

export interface IMapDisplayProps {
  locations: ILocationProps[];
  zoom?: number;
  center?: {
    lat: number;
    lng: number;
  };
  markerImage?: string;
}

const MarkerLoader: React.FC<IMapDisplayProps> = ({ locations, markerImage }) => {
  const { selectedMapId } = useSelectedMapId();
  const [ setMapId, unsetMapId ] = useUpdateMapId();
  return (
    <>
      {_map(locations, ({ latitude, longitude, name, address, telephoneNumber, telephoneLabel }) => {
        const key = `${latitude}${longitude}`;
        const icon = markerImage ? { icon: { url: markerImage } }: undefined;

        return (
          <Marker
            { ...icon }
            key={key}
            onClick={() => setMapId(key)}
            position={{ lat: latitude, lng: longitude }}
          >
            {selectedMapId === key &&
              <InfoWindow onCloseClick={() => unsetMapId()}>
                <div>
                  <Heading
                    as="h3"
                    sx={{
                      textTransform: 'uppercase',
                      lineHeight: '1em',
                      mb: '4px',
                      fontWeight: 'bold',
                      fontSize: [8, 12],
                    }}
                  >
                    {name}
                  </Heading>
                  <ComponentListRenderer listText={address} />
                  {telephoneLabel && telephoneNumber && <Box sx={{ fontWeight: 'bold', mt: '8px' }}>{telephoneLabel}</Box>}
                  {telephoneNumber && <Box sx={{ mt: '4px' }}>{telephoneNumber}</Box>}
                </div>
              </InfoWindow>
            }
          </Marker>
        );
      })}
    </>
  );
};

export default MarkerLoader;
