import * as React from 'react';

import { IMapId } from '../types';
import { FranchiseeActionTypes } from './Action';

interface IMapIdContext {
  state: IMapId;
  dispatch: React.Dispatch<FranchiseeActionTypes>;
}

export const MapIdContext = React.createContext<IMapIdContext | undefined>(undefined);
