import * as React from 'react';

import { MapIdContext, MapIdActions } from '../context';

export const useUpdateMapId = () => {
  const context = React.useContext(MapIdContext);

  if (!context) {
    throw new Error('useUpdateMapId must be used within an MapIdProvider');
  }

  const { dispatch } = context;

  const setMapId = (selectedMapId: string): void => {
    dispatch({ type: MapIdActions.SET_ID, payload: selectedMapId });
  };

  const unsetMapId = (): void => {
    dispatch({ type: MapIdActions.UNSET_ID });
  };

  return [ setMapId, unsetMapId ] as [ (selectedMapId: string) => void, () => void ];
};
