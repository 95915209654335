import * as React from 'react';
import MarkerClusterer, { MarkerClustererProps } from 'react-google-maps/lib/components/addons/MarkerClusterer';

import clusterImg from './clusterImg-50x50.png';

export interface IMarkerClusterer extends MarkerClustererProps {
  children: React.ReactNode;
  enableDefaultClusterIcon?: boolean;
}

const defaultClusterStyle = [
  {
    url: clusterImg,
    height: 50,
    width: 50,
  },
];

const LceMarkerClusterer: React.FC<IMarkerClusterer> = (props) => {
  const { children, enableDefaultClusterIcon, styles } = props;
  const loadStyles = enableDefaultClusterIcon ? {} : { styles: styles || defaultClusterStyle };

  const defaultProps = {
    averageCenter: true,
    enableRetinaIcons: true,
    gridSize: 30,
    minimumClusterSize: 3,
    ...loadStyles,
    ...props,
  };

  return (
    <MarkerClusterer
      { ...defaultProps }
    >
      { children }
    </MarkerClusterer>
  );
};

export default LceMarkerClusterer;
