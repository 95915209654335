import * as React from 'react';

import GoogleMap, { IMapDisplay } from './GoogleMap';

export interface IGoogleMaps extends IMapDisplay {
  apiKey: string;
  containerElement: any;
}

const DefaultMap: React.FC<IGoogleMaps> = ({
  apiKey, children, containerElement, ...props
}) => (
  <GoogleMap
    { ...props }
    containerElement={ containerElement }
    googleMapURL={ `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${ apiKey }
      &libraries=geometry,drawing,places` }
    loadingElement={ <div style={ { height: '100%' } } /> }
    mapElement={ <div style={ { height: '100%', width: '100%' } } /> }
  >
    {children}
  </GoogleMap>
);

export default DefaultMap;
