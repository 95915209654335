import * as React from 'react';
import Heading from '@lce/slice_v2/Elements/Heading';

const PageTitle: React.FC = ({ children }) => (
  <Heading
    as='h1'
    sx = {{
      fontSize: 18,
      mb: '24px',
      lineHeight: 0.83,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }}
  >
    { children }
  </Heading>
);

export default PageTitle;
