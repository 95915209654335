/* eslint-disable no-negated-condition */
/* eslint-disable babel/no-unused-expressions */
import * as React from 'react';
import { map as _map } from 'lodash';
import {
  withScriptjs, withGoogleMap, GoogleMap, GoogleMapProps,
} from 'react-google-maps';

import { style as defaultMapStyle } from './mapStyle';

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type MapStyles = google.maps.MapTypeStyle[];

export interface IMapDisplay extends GoogleMapProps {
  zoomDefault?: number;
  centerDefault?: IPosition;
  enableScrollWheel?: boolean;
  disableAutoPanZoom?: boolean;
  enableDefaultGoogleMapStyle?: boolean;
}
export interface IPosition {
  lat: number;
  lng: number;
}

const getPositions = (children: React.ReactNode) => {
  let pos = [] as any[];

  React.Children.forEach(children, (element: any) => {
    if (!React.isValidElement(element)) return;

    (element as any).props.locations && 
      _map((element as any).props.locations, location => { pos = [ ...pos, location.position ]; });

    (element as any).props.children && React.Children.forEach((element as any).props.children, (child) => {
      if (!React.isValidElement(child)) return;

      // eslint-disable-next-line max-statements-per-line
      (child as any).props?.locations && _map((child as any).props.locations, location => { pos = [ ...pos, location.position ]; });
    });
  });

  return pos;
};

const fitBounds = (map: google.maps.Map, pos: any[]): void => {
  const bounds = new window.google.maps.LatLngBounds();

  if (!map) return;

  // eslint-disable-next-line array-callback-return
  pos.map((place) => {
    bounds.extend(place);
  });

  if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
    const extendPoint1 = new (window as any).google.maps.LatLng(
      bounds.getNorthEast().lat() + 0.01,
      bounds.getNorthEast().lng() + 0.01,
    );

    const extendPoint2 = new (window as any).google.maps.LatLng(
      bounds.getNorthEast().lat() - 0.01,
      bounds.getNorthEast().lng() - 0.01,
    );

    bounds.extend(extendPoint1);
    bounds.extend(extendPoint2);
  }

  // eslint-disable-next-line consistent-return
  return map.fitBounds(bounds);
};

const Map: React.FC<IMapDisplay> = ({
  zoomDefault,
  enableDefaultGoogleMapStyle = false,
  enableScrollWheel = false,
  centerDefault,
  children,
  defaultOptions,
  disableAutoPanZoom = false,
}) => {
  const loadHandler = (map: any) => {
    !disableAutoPanZoom && fitBounds(map, getPositions(children));
  };
  const scrollWheel = !enableScrollWheel ? { scrollwheel: false } : null;
  const styles = defaultOptions && defaultOptions.styles
    ? defaultOptions.styles
    : !enableDefaultGoogleMapStyle ? defaultMapStyle as google.maps.MapTypeStyle[] : undefined;
  return (
    <GoogleMap
      center={ centerDefault }
      defaultOptions={ {
        ...scrollWheel,
        ...defaultOptions,
        styles,
      } }
      ref={ map => map && loadHandler(map) }
      zoom={ zoomDefault }
    >
      {children}
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(Map));
