import * as React from 'react';
import { map as _map, isArray as _isArray } from 'lodash';
import { BoxProps } from 'theme-ui';
import Box from '@lce/slice_v2/Layout/Box';
import Heading from '@lce/slice_v2/Elements/Heading';
import { useUpdateMapId } from '@lce/intl-util/src/api/SelectedMapId/hooks/useUpdateMapId';

import ComponentListRenderer from '../../../../../Modules/ComponentListRenderer';

export interface IStoreLocationProps {
  locale: string;
  address: string | string[];
  name: string;
  telephoneNumber?: string;
  hours?: string | string[];
  latitude: number;
  longitude: number;
}

export interface ILocationListDefaultProps {
  pageTitle: string;
  hoursLabel: string;
  telephoneLabel: string;
  defaultHours: string | string[];
  isSorted: boolean;
}

// eslint-disable-next-line max-lines-per-function
const StoreLocation: React.FC<IStoreLocationProps & ILocationListDefaultProps> = ({
  name,
  address,
  telephoneNumber,
  hours,
  hoursLabel,
  latitude,
  longitude,
  telephoneLabel,
  defaultHours,
}) => {
  const [ setMapId ] = useUpdateMapId();

  const hasHours = (hours && hours.length > 0) || (defaultHours && defaultHours.length > 0);
  const LocationDetail: React.FC<BoxProps> = ({ children, ...props }) => (<Box {...props} sx={{ m: 0, '&:last-of-type': { mt: '8px' } }}>{children}</Box>);
  const key = `${latitude}${longitude}`;
  return (
    <Box onClick={() => setMapId(key)} sx={{ cursor: 'pointer' }}>
      <Box sx={{ fontSize: '20px', lineHeight: '1.2em', p: '0 0 12px', flexDirection: 'row' }}>
        <Heading
          as="h2"
          sx={{ 
            textTransform: 'uppercase',
            fontSize: '24px',
            lineHeight: '1em',
            mb: '4px',
            fontWeight: 'bold',
          }}
        >
          {name}
        </Heading>
        <ComponentListRenderer Component={LocationDetail} listText={address} />
        {telephoneNumber && (
          <LocationDetail>
            {telephoneLabel && telephoneLabel} {telephoneNumber}
          </LocationDetail>
        )}
        <LocationDetail data-testid="locationHours">
          {hasHours && (
            <>
              {hoursLabel}
              <Box data-testid="locationHoursList" sx={{ listStyleType: 'none', m: '0 0 4px' }}>
                {_map(_isArray(hours) && hours.length > 0 ? hours : defaultHours, (hour, index) => (
                  <Box key={index} sx={{ m: 0 }}>{hour}</Box>
                ))}
              </Box>
            </>
          )}
        </LocationDetail>
      </Box>
    </Box>
  );
};

export default StoreLocation;
