import * as React from 'react';

import { MapIdContext } from '../context';
import { IMapId } from '../types';

export const useSelectedMapId = (): IMapId => {
  const context = React.useContext(MapIdContext);

  if (!context) {
    throw new Error('useSelectedMapId must be used within an MapIdProvider');
  }

  const { state } = context;

  return state;
};
