import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import { Map, MarkerClusterer } from '@lce/intl-map/src';

import MarkerLoader, { IMapDisplayProps } from './components/MarkerLoader/MarkerLoader';

export interface ILocationProps {
  latitude: number;
  longitude: number;
  name: string;
  address: string[];
  telephoneNumber: string;
  telephoneLabel: string;
}

interface IMapProps extends IMapDisplayProps {
  apiKey: string;
}

const DefaultMap: React.FC<IMapProps> = ({ apiKey, ...props }) => {
  const positionDefaults = props.center && {
    centerDefault: props.center ? props.center : { lat: 0, lng: 0 },
    zoomDefault: props.zoom ? props.zoom : 16,
  };

  const mapsConfig = {
    apiKey,
    defaultOptions: {
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    },
  };

  return (
    <Map { ...mapsConfig } { ...positionDefaults } containerElement={ <Box className="StoreLcationMap" sx={{ width: ['100%', '50%'], height: '624px' }} /> }>
      <MarkerClusterer>
        <MarkerLoader locations={ props.locations } markerImage={ props.markerImage } />
      </MarkerClusterer>
    </Map>
  );
};

export default DefaultMap;
